<template lang="pug">
.h-auto.flex.w-full.mt-5
  vs-row
    vs-col(
      vs-offset="3" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6")
      vx-card
        form
          vs-row(
            vs-w="12"
          )
            vs-col(
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-lg="10"
              vs-sm="10"
              vs-xs="12"
            )
              span.text-data(
                class="ml-4 mt-1"
              ) EXPORTABLE GENERAL
            vs-col(
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-lg="2"
              vs-sm="2"
              vs-xs="12"
            )
          vs-divider
          div(
            class="vx-row"
          )
            div(
              class="vx-col sm:w-1/2 w-full mb-2"
            )
              label(
                class="vs-select--label"
              ) Fecha Inicio
              vs-input.w-full(
                type="date"
                name="first_name"
                icon="icon icon-calendar"
                icon-pack="feather"
                v-model="date_init"
                autocomplete="off"
              )
            div(
              class="vx-col sm:w-1/2 w-full mb-2"
            )
              label(
                class="vs-select--label"
              ) Fecha Fin
              vs-input.w-full(
                type="date"
                name="last_name"
                icon="icon icon-calendar"
                icon-pack="feather"
                v-model="date_finish"
                autocomplete="off"
              )
          div(
            class="flex justify-between text-center mt-5"
          )
            vs-button.style-buttom.space-buttom.buttom-save(
              color="primary"
              type="filled"
              :disabled="show_button"
              @click.prevent="exportcsv"
            ) Exportar
            h3(v-if="show_button") Cargando por favor espere
    vs-col.mt-4(
      vs-offset="3" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6")
      vx-card
        form
          vs-row(
            vs-w="12"
          )
            vs-col(
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-lg="10"
              vs-sm="10"
              vs-xs="12"
            )
              span.text-data(
                class="ml-4 mt-1"
              ) EXPORTABLE GENERAL SIN _P
            vs-col(
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              vs-lg="2"
              vs-sm="2"
              vs-xs="12"
            )
          vs-divider
          div(
            class="vx-row"
          )
            div(
              class="vx-col sm:w-1/2 w-full mb-2"
            )
              label(
                class="vs-select--label"
              ) Fecha Inicio
              vs-input.w-full(
                type="date"
                name="first_name"
                icon="icon icon-calendar"
                icon-pack="feather"
                v-model="date_init"
                autocomplete="off"
              )
            div(
              class="vx-col sm:w-1/2 w-full mb-2"
            )
              label(
                class="vs-select--label"
              ) Fecha Fin
              vs-input.w-full(
                type="date"
                name="last_name"
                icon="icon icon-calendar"
                icon-pack="feather"
                v-model="date_finish"
                autocomplete="off"
              )
          div(
            class="flex justify-between text-center mt-5"
          )
            vs-button.style-buttom.space-buttom.buttom-save(
              color="primary"
              type="filled"
              :disabled="show_button"
              @click.prevent="exportcsv2"
            ) Exportar
            h3(v-if="show_button") Cargando por favor espere
</template>
<script>
import HTTP from "@/http-common"

const NAME = "UserForm"

export default {
  name: NAME,
  data() {
    return {
      date_init: null,
      date_finish: null,
      show_button: false
    }
  },
  methods: {
    async exportcsv() {
      this.show_button = true
      const headers = {
        estado: 'estado',
        guia: 'guia',
        cod_sim_MSISDN: 'cod_sim_MSISDN',
        cod_sim_IMSI: 'cod_sim_IMSI',
        documento: 'documento',
        destinatario: 'destinatario',
        cx: 'cx',
        cy: 'cy',
        fecha_entrega: 'fecha_entrega',
        hora_entrega: 'hora_entrega',
        direccion: 'direccion',
        ciudad: 'ciudad',
        telefono: 'telefono',
        celular: 'celular',
        responsable: 'responsable',
        tipo_visita: 'tipo_visita',
        cantidad_sim_card: 'cantidad_sim_card',
        plan: 'plan',
        valor_a_recaudar: 'valor_a_recaudar',
        canal: 'canal',
        quien_firma: 'quien_firma',
        encuesta1: 'encuesta1',
        encuesta2: 'encuesta2',
        encuesta3: 'encuesta3',
        estado_geo: 'estado_geo',
        restriccion: 'restriccion',
        fecha_creacion: 'fecha_creacion',
        fecha_aceptacion: 'fecha_aceptacion',
        fecha_llegada: 'fecha_llegada',
        fecha_cierre: 'fecha_cierre',
        novedad: 'novedad',
        observacion: 'observacion',
        foto: 'foto',
        firma: 'firma',
        anoexpedicion: 'anoexpedicion',
        linea_migrar: 'linea_migrar',
        nip: 'nip',
        id_vendedor: 'id_vendedor',
        estado_archivo: 'estado_archivo',
        fecha_televenta: 'fecha_televenta',
        usuario_modificador: 'usuario_modificador',
        campana: 'campana',
        email: 'email',
        IMSI: 'IMSI',
        MSISDN: 'MSISDN',
        jornada: 'jornada',
        contact_center: 'contact_center',
        usuario_creador: 'usuario_creador',
        forma_pago: 'forma_pago',
        cedula_responsable: 'cedula_responsable',
        regional_ciudad: 'regional_ciudad',
        zonificacion: 'zonificacion',
        autorizacion: 'autorizacion'
      };
      const data = await HTTP.get(`https://integracion.smartquick.com.co/exporteRecursos_prueba.php?fecha1=${this.date_init}&fecha2=${this.date_finish}`).then((response) => {
        // console.log(response)
        return response.data
      })
      this.exportCSVFile(headers, data, 'Exportable General')
    },
    convertToCSV(objArray) {
      const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
        if (line != "") line += ";";
      line += array[i][index];
        }
      str += line + "\r\n";
      }
      return str;
    },
    exportCSVFile(headers, items, fileName) {
      if (headers) {
        items.unshift(headers);
      }
      const jsonObject = JSON.stringify(items);
      const csv = this.convertToCSV(jsonObject);
      const exportName = fileName + ".csv" || "export.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
      } else {
          const link = document.createElement("a");
          if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.show_button = false
        }
      }
    },
    async exportcsv2() {
      this.show_button = true
      const headers = {
        guia: 'guia',
        num_visitas: 'num_visitas',
        documento: 'documento',
        destinatario: 'destinatario',
        ciudad: 'ciudad',
        direccion: 'direccion',
        telefono: 'telefono',
        celular: 'celular',
        tipo_visita: 'tipo_visita',
        canal: 'canal',
        plan: 'plan',
        valor_a_recaudar: 'valor_a_recaudar',
        restriccion: 'restriccion',
        observacion: 'observacion',
        anoexpedicion: 'anoexpedicion',
        linea_migrar: 'linea_migrar',
        nip: 'nip',
        id_vendedor: 'id_vendedor',
        fecha_televenta: 'fecha_televenta',
        campana: 'campana',
        cantidad_sim_card: 'cantidad_sim_card',
        email: 'email',
        usuario_creador: 'usuario_creador',
        usuario_modificador: 'usuario_modificador',
        forma_pago: 'forma_pago',
        cx: 'cx',
        cy: 'cy',
        estado: 'estado',
        fecha_creacion_guia: 'fecha_creacion_guia',
        fecha_entrega: 'fecha_entrega',
        hora_entrega: 'hora_entrega',
        fecha_aceptacion: 'fecha_aceptacion',
        fecha_llegada: 'fecha_llegada',
        fecha_cierre: 'fecha_cierre',
        novedad: 'novedad',
        foto: 'foto',
        firma: 'firma',
        quien_firma: 'quien_firma',
        jornada: 'jornada',
        contact_center: 'contact_center',
        regional_ciudad: 'regional_ciudad',
        responsable: 'responsable',
        cedula_responsable: 'cedula_responsable'
      };
      const data = await HTTP.get(`https://integracion.smartquick.com.co/exporteRecursos_prueba_nuevo.php?fecha1=${this.date_init}&fecha2=${this.date_finish}`).then((response) => {
        // console.log(response)
        return response.data
      })
      this.exportCSVFile2(headers, data, 'Exportable General')
    },
    convertToCSV2(objArray) {
      const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      let str = "";
      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
        if (line != "") line += ";";
      line += array[i][index];
        }
      str += line + "\r\n";
      }
      return str;
    },
    exportCSVFile2(headers, items, fileName) {
      if (headers) {
        items.unshift(headers);
      }
      const jsonObject = JSON.stringify(items);
      const csv = this.convertToCSV2(jsonObject);
      const exportName = fileName + ".csv" || "export.csv";
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
      } else {
          const link = document.createElement("a");
          if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.show_button = false
        }
      }
    }
  }
}
</script>
<style module>
.mbs3 {
  margin-top: -0.3em;
}
</style>
<style lang="scss" scoped>
.btn-margin-3 .vs-button {
  height: 69% !important;
  margin-top: 1.2em !important;
}
</style>