<template lang="pug">
  .vx-card__title.mb-8
    change-language(class="float-right")
    h4.mb-4 {{ $t('auth.recovery') }}
    //- p {{ $t('auth.welcome')}}
    form.pt-3
      vs-input.w-full.no-icon-border(
        v-validate="'required|email|min:3'"
        name="email"
        :data-vv-as="$t('auth.email')"
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        v-model="email"
      )
      span.text-danger.text-sm(
        v-show="errors.has('email')"
      ) {{ errors.first('email') }}
      .flex.flex-wrap.justify-center.my-5
        router-link(
          :to="{ name: 'Login'}"
        ) {{ $t('auth.haveaccount') }}
      .flex.flex-wrap.justify-center.my-5
        vs-button.float-right(
          @click.prevent="Recovery"
        ) {{ $t('auth.recovery') }}
</template>

<script>

import ChangeLanguage from "@/components/lang/ChangeLanguage"
import ErrorNotify from "@/components/errors/Errors"
import HTTP from "@/http-common.js"

// Constantes
const COMPONENT_NAME = "ResetPassword"

export default {
  name: COMPONENT_NAME,
  components: {
    "change-language": ChangeLanguage
  },
  data() {
    return {
      email: ""
    }
  },
  methods: {
    Recovery() {
      this.$validator.validateAll().then(result => {
        if (result) {
          HTTP.post("/rest-auth/password/reset/", { email: this.email })
            .then(() => {
              this.$swal({
                position: "center",
                type: "success",
                title: this.$t("auth.email-plataform"),
                confirmButtonColor: "#FDD402",
                cancelButtonColor: "#ea5455",
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 3000
              }).then(result => {
                if (result) {
                  this.$router.push({ name: "Home" })
                }
              })
            })
            .catch(response => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this
              })
            })
        }
      })
    }
  }
}
</script>