<template lang="pug">
  vue-recovery
</template>

<script>

import RecoveryPassword from "@/components/auth/RecoveryPassword"

// Constantes
const COMPONENT_NAME= "RecoveryPassword"

export default {
  name: COMPONENT_NAME,
  components: {
    "vue-recovery": RecoveryPassword
  }
}
</script>